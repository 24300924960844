<template>
  <template v-for="menu in menuData" :key="menu.path">
    <el-menu-item :index="menu.path" v-if="!menu.children && !menu.hidden">
      <template #title>
        <span class="iconfont" :class="menu.meta.icon" v-if="menu.meta.level == 1"></span>
        <span>{{menu.meta.title}}</span>
      </template>
    </el-menu-item>
    <el-sub-menu :index="menu.path" v-else-if="!menu.hidden" popper-class="popper-meun">
      <template #title>
        <span class="iconfont" :class="menu.meta.icon" v-if="menu.meta.level == 1"></span>
        <span>{{menu.meta.title}}</span>
      </template>
      <menu-tree :menu-data="menu.children" />
    </el-sub-menu>
  </template>
</template>

<script>
  import { House } from '@element-plus/icons'
  export default {
    components: { House },
    props: {
      menuData: Array
    },
    setup() {
      return {}
    }
  }
</script>

<style scoped>
  .iconfont{
    margin-right: 10px;
    font-size: 18px;
    display: inline-block;
    width: 18px;
  }
  .el-menu-item.is-active{
    background-color: var(--el-color-primary);
    color: #fff;
  }
</style>