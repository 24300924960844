import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/icon/iconfont.css'
import '@/assets/main.scss'

createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus, { locale: zhCn, zIndex: 3000, size: 'default' })
    .mount('#app')

document.title = 'ERP-管理系统';
document.execCommand("BackgroundImageCache", false, true);
