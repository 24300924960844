<template>
  <el-menu :default-active="activerouter" class="el-menu-vertical-demo" background-color="#FAFAFA"
    text-color="#232323" router unique-opened :collapse="isCollapse" :collapse-transition="false">
    <menu-tree :menu-data="menuData" />
  </el-menu>
</template>

<script>
  import { onMounted, reactive, toRefs } from 'vue'
  import menuTree from './menuTree'

  export default {
    props: {
      menuData: Array,
      isCollapse: {
        type: Boolean,
        default: false
      }
    },
    components: {
      menuTree,
    },
    setup() {
      const data = reactive({
        activerouter: ''
      })

      onMounted(() => {
        let defaultMenu = window.location.hash.substr(
          window.location.hash.indexOf("/")
        )
        data.activerouter = defaultMenu
      })
      
      return {
        ...toRefs(data)
      }
    },
  }
</script>

<style scoped>
</style>