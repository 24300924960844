import { createStore } from 'vuex'

export default createStore({
  state: {
    msgNum: 0,
    orderNum: 0,
    noticeNum: 0,
    workNum: 0,
    tousuNum: 0,
    annNum: 0,
    orderType: '',
    orderId: '',
    workId: '',
    msgDialog: false
  },
  mutations: {
    SET_MSGNUM: (state, num) => {
      state.msgNum = num
    },
    SET_ORDERNUM: (state, num) => {
      state.orderNum = num
    },
    SET_NOTICENUM: (state, num) => {
      state.noticeNum = num
    },
    SET_WORKNUM: (state, num) => {
      state.workNum = num
    },
    SET_TOUSUNUM: (state, num) => {
      state.tousuNum = num
    },
    SET_ORDERTYPE: (state, val) => {
      state.orderType = val
    },
    SET_ORDERID: (state, val) => {
      state.orderId = val
    },
    SET_WORKID: (state, val) => {
      state.workId = val
    },
    SET_MSGDIALOG: (state, flag) => {
      state.msgDialog = flag
    },
    SET_ANNNUM: (state, num) => {
      state.annNum = num
    },
  },
  actions: {
    setMsgNum({ commit }, num) {
      commit('SET_MSGNUM', num)
    },
    setOrderNum({ commit }, num) {
      commit('SET_ORDERNUM', num)
    },
    setNoticeNum({ commit }, num) {
      commit('SET_NOTICENUM', num)
    },
    setWorkNum({ commit }, num) {
      commit('SET_WORKNUM', num)
    },
    setTousuNum({ commit }, num) {
      commit('SET_TOUSUNUM', num)
    },
    setOrderType({ commit }, val) {
      commit('SET_ORDERTYPE', val)
    },
    setOrderId({ commit }, val) {
      commit('SET_ORDERID', val)
    },
    setWorkId({ commit }, val) {
      commit('SET_WORKID', val)
    },
    setMsgDialog({ commit }, flag) {
      commit('SET_MSGDIALOG', flag)
    },
    setAnnNum({ commit }, num) {
      commit('SET_ANNNUM', num)
    }
  },
  modules: {
  }
})
