import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from 'views/layout/index.vue'

export const routes = [
  {
    path: '/',
    redirect: '/login',
    hidden: true,
    component: () => import('views/login/index.vue'),
    
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('views/login/index.vue')
  },
  {
    path: '/404',
    name: '404',
    hidden: true,
    component: () => import('views/login/404.vue')
  },
  {
    path: '/',
    redirect: '/home/index',
    component: Layout,
    hidden: false,
    meta: { title: '首页' },
    children: [
      {
        path: 'home/index',
        meta: { title: '首页', level: 1, icon: 'icon-home' },
        component: () => import('views/home/index')
      }
    ]
  },
  {
    path: '/',
    redirect: '/commodity/list',
    hidden: false,
    component: Layout,
    meta: { title: '首页' },
    children: [
      {
        path: 'commodity/list',
        meta: { title: '商品列表', level: 1, icon: 'icon-list' },
        component: () => import('views/commodity/list')
      }
    ]
  },
  // {
  //   path: '/statistics',
  //   hidden: false,
  //   redirect: '/statistics/personal',
  //   meta: { title: '统计', level: 1, icon: 'icon-tongji' },
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/statistics/personal',
  //       name: 'personal',
  //       meta: {
  //         title: '个人中心'
  //       },
  //       component: () => import('views/statistics/personal')
  //     },
  //     {
  //       path: '/statistics/achievement',
  //       name: 'achievement',
  //       meta: {
  //         title: '业绩中心'
  //       },
  //       component: () => import('views/statistics/achievement')
  //     },
  //     {
  //       path: '/statistics/ranking',
  //       name: 'ranking',
  //       meta: {
  //         title: '业绩排名'
  //       },
  //       component: () => import('views/statistics/ranking')
  //     }
  //   ]
  // },
  {
    path: '/',
    hidden: false,
    redirect: '/commodity/sku',
    component: Layout,
    meta: { title: '首页' },
    children: [
      {
        path: 'commodity/sku',
        hidden: false,
        meta: { title: '商品规格', level: 1, icon: 'icon-sku' },
        component: () => import('views/commodity/sku')
      }
    ]
  },
  {
    path: '/',
    hidden: false,
    redirect: '/commodity/combination',
    component: Layout,
    meta: { title: '首页' },
    children: [
      {
        path: 'commodity/combination',
        hidden: false,
        meta: { title: '组合商品', level: 1, icon: 'icon-cc' },
        component: () => import('views/commodity/combination')
      }
    ]
  },
  {
    path: '/',
    hidden: false,
    redirect: '/commodity/brand',
    component: Layout,
    meta: { title: '首页' },
    children: [
      {
        path: 'commodity/brand',
        hidden: false,
        meta: { title: '商品品牌', level: 1, icon: 'icon-brand' },
        component: () => import('views/commodity/brand')
      }
    ]
  },
  {
    path: '/',
    hidden: false,
    redirect: '/purchase/index',
    component: Layout,
    meta: { title: '首页' },
    children: [
      {
        path: 'purchase/index',
        hidden: false,
        meta: { title: '采购', level: 1, icon: 'icon-caigou' },
        component: () => import('views/purchase/index')
      }
    ]
  },
  {
    path: '/',
    hidden: false,
    redirect: '/warehouse/index',
    component: Layout,
    meta: { title: '首页' },
    children: [
      {
        path: 'warehouse/index',
        hidden: false,
        meta: { title: '仓库', level: 1, icon: 'icon-cangku' },
        component: () => import('views/warehouse/index')
      }
    ]
  },
  {
    path: '/',
    hidden: false,
    redirect: '/warehouse/io',
    component: Layout,
    meta: { title: '首页' },
    children: [
      {
        path: 'warehouse/io',
        hidden: false,
        meta: { title: '出入库', level: 1, icon: 'icon-churuku' },
        component: () => import('views/warehouse/io')
      }
    ]
  },
  {
    path: '/',
    hidden: false,
    redirect: '/supplier/index',
    component: Layout,
    meta: { title: '首页' },
    children: [
      {
        path: 'supplier/index',
        hidden: false,
        meta: { title: '供应商', level: 1, icon: 'icon-supplier' },
        component: () => import('views/supplier/index')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    if (localStorage.getItem('token')) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
