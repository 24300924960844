import axios from 'axios'
import router from '@/router'
import { ElMessage } from 'element-plus'

let showMsg = true;
function interceptRequest () {
  axios.interceptors.request.use(config =>
  {
    config.headers['content-type'] = 'application/json'
    config.headers.Authorization = localStorage.getItem('token') || ''
    config.headers['Shop-Id'] = localStorage.getItem('shopId') || ''

    // 登录接口、登出接口、刷新token接口不需要token
	  if (config.url.indexOf('/auth/refresh') >= 0 || config.url.indexOf('/auth/login') >= 0 || config.url.indexOf('/auth/logout') >= 0) {
	    return config
	  }
    let access_token = localStorage.getItem('token')
    let saveTime = Number(localStorage.getItem('saveTime'))
		let expires_in = Number(localStorage.getItem('expires_in'))
    
    // 如果token已经过期
    if((access_token && saveTime && expires_in && (Date.now() > saveTime + expires_in)) || !access_token) {
      ElMessage('登录用户已过期，请重新登录')
      localStorage.removeItem('token')
      localStorage.removeItem('saveTime')
      localStorage.removeItem('bind_wechat')
      router.replace({
        path: '/login'
      })
      Reflect.deleteProperty(config, 'headers')
      return config
    }
    return config
  }, err => {
    return Promise.reject(err)
  })

  axios.interceptors.response.use(response => {
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      return response
    }
    // return response
  }, err => {
    return Promise.reject(err)
  })
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get (url = '', params = {}, responseType = '') {
  return new Promise((resolve, reject) => {
    interceptRequest()
    axios
      .get(url, {
        params: params,
        responseType
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        if (showMsg && err.response && err.response.data && err.response.data.message) {
          ElMessage(err.response.data.message)
        }
        reject(err)
      })
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 * @param { string } requestType 请求类型 默认为post
 */
export function post (url = '', data = {}, requestType = 'post') {
  requestType = requestType.toLowerCase()
  return new Promise((resolve, reject) => {
    interceptRequest()
    let dataObj = {}
    dataObj = requestType === 'delete' ? { data } : data
    axios[requestType](url, { ...dataObj })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        if (showMsg && err.response && err.response.data && err.response.data.message) {
          ElMessage(err.response.data.message)
        }
        reject(err)
      })
  })
}

/**
 * post方法，用于处理图片等文件流
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 * @param { string } requestType 请求类型 默认为post
 */
export function postForm(url = '', data = {}, requestType = 'post') {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    const item = data[key]
    if (key === 'goods') {
      return
    }
    if (Array.isArray(data[key])) {
      if (key === 'specs') {
        formData.append(key, JSON.stringify(item));
        return
      }
      if (item.length) {
        item.forEach( (j, index) => {
          if (Object.prototype.toString.call(j) === '[object Object]') {
            Object.keys(j).forEach(jkey => {
              formData.append(`${key}[${index}][${jkey}]`, j[jkey]);
            })
          } else {
            formData.append(`${key}[]`, j || '');
          }
        })
      } else {
        formData.append(`${key}`, []);
      }

    } else if (Object.prototype.toString.call(item) === '[object Object]') {
      Object.keys(item).forEach(jkey => {
          formData.append(`${key}[${jkey}]`, item[jkey]);
      })
    } else {
      if(data[key] === 0) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, data[key] || '');
      }
      
    }
  })
  return new Promise((resolve, reject) => {
    interceptRequest()
    axios[requestType](url, formData)
      .then(res => {
          resolve(res.data)
        })
      .catch(err => {
        ElMessage({
          type: 'warning',
          message: err.response.data.message
        })
        reject(err)
      })
  })
}
