<template>
  <router-view />
</template>

<script src="@/assets/icon/iconfont.js"></script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
}

/*解决element表格不对齐*/
body .el-table th.gutter {
  display: table-cell !important;
}
.page-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-top: 15px;
}
.scrollbar-wrapper {
  overflow-x: hidden !important;
}
/*解决element上传组件回显动画问题*/
body .el-upload-list__item {
  transition: none !important;
}
.pageBox{
  // height: 100%;
  // overflow: auto;
  padding: 15px;
  box-sizing: border-box;
}
body .el-message-box__title{
  font-size: 16px;
}
body .el-page-header__back{
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  padding: 2px 8px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: rgba(0,0,0,0.2);
}
.vanMsg.el-message{
  left: 60px;
  transform: none;
}
</style>
